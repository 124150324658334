import React from 'react'
import styled from 'styled-components'

const docUrl = "https://drive.google.com/file/d/1aiRyMMxPSwY4WLpNmrPG3HEsWbNwE8Qh/preview";

const TermsFrame = styled.iframe`
  width: 100%;
  height: 100vh;
  width: 100%;
  border: none;
`

const PrivacyPage = () => <TermsFrame src={docUrl} />


export default PrivacyPage;
